import { createContext, ReactNode, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { User } from 'src/types/user';

import { api } from '../services/apiClient';

type SignInCredentials = {
  userEmail: string;
  userPassword: string;
};

type AuthContextData = {
  signIn: (credentials: SignInCredentials) => Promise<void>;
  signOut: () => void;
  user: User;
  isAuthenticated: boolean;
};

type AuthProviderProps = {
  children: ReactNode;
};

export function signOut() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  <Navigate to="/authentication/login" />;
}

export const AuthContext = createContext({} as AuthContextData);
export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User>();
  const isAuthenticated = !!user;

  useEffect(() => {
    const accessToken = window.localStorage.getItem('accessToken');

    if (accessToken) {
      api
        .get<User>('/auth/me')
        .then((response) => {
          const dataUser = response.data;
          setUser(dataUser);
        })
        .catch(() => {
          signOut();
        });
    }
  }, []);

  async function signIn({ userEmail, userPassword }: SignInCredentials) {
    const dataPost = new FormData();
    dataPost.append('email', userEmail);
    dataPost.append('password', userPassword);

    const response = await api.post('/auth/login', dataPost);

    if (!response?.data) {
      throw {
        response: {
          data: {
            message: 'Nao foi possível realizar o login',
          },
        },
      };
    }

    const { token, id } = response.data;
    localStorage.setItem('accessToken', token);
    localStorage.setItem('refreshToken', token);

    setUser({ id });

    api.defaults.headers['Token'] = `Bearer ${token}`;
    <Navigate to="/dashboard" />;
  }

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        isAuthenticated,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
