import { lazy, Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));

// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import('./pages/AuthorizationRequired'))
);
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Sao Paulo Dental Studio
const TrainingList = Loadable(
  lazy(() => import('./pages/dashboard/training/TrainingList'))
);

const TrainingEdit = Loadable(
  lazy(() => import('./pages/dashboard/training/TrainingEdit'))
);

const TestimonialList = Loadable(
  lazy(() => import('./pages/dashboard/testimonial/TestimonialList'))
);

const TestimonialEdit = Loadable(
  lazy(() => import('./pages/dashboard/testimonial/TestimonialEdit'))
);

const CollaboratorList = Loadable(
  lazy(() => import('./pages/dashboard/collaborator/CollaboratorList'))
);

const CollaboratorEdit = Loadable(
  lazy(() => import('./pages/dashboard/collaborator/CollaboratorEdit'))
);

const LeadList = Loadable(
  lazy(() => import('./pages/dashboard/lead/LeadList'))
);

const routes: RouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: '',
        element: <Navigate to="/authentication/login" replace />,
      },
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: 'login-unguarded',
        element: <Login />,
      },
    ],
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="/dashboard/training" replace />,
      },
      {
        path: 'training',
        children: [
          {
            path: '',
            element: <TrainingList />,
          },
          {
            path: 'new',
            element: <TrainingEdit />,
          },
          {
            path: ':id/edit',
            element: <TrainingEdit />,
          },
          {
            path: ':id/testimonial',
            children: [
              {
                path: '',
                element: <TestimonialList />,
              },
              {
                path: 'new',
                element: <TestimonialEdit />,
              },
              {
                path: ':idTestimonial/edit',
                element: <TestimonialEdit />,
              },
            ],
          },
        ],
      },
      {
        path: 'collaborator',
        children: [
          {
            path: '',
            element: <CollaboratorList />,
          },
          {
            path: 'new',
            element: <CollaboratorEdit />,
          },
          {
            path: ':id/edit',
            element: <CollaboratorEdit />,
          },
        ],
      },
      {
        path: 'lead',
        children: [
          {
            path: '',
            element: <LeadList />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="/authentication/login" replace />,
      },
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
