import axios, { AxiosError } from 'axios';
import { signOut } from 'src/contexts/AuthContext';

let isRefreshing = false;
let failedRequestsQueue = [];

export function setupAPIClient() {
  const token = window.localStorage.getItem('accessToken');
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Token: `Bearer ${token}`,
    },
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error: AxiosError) => {
      if (error.response.status === 401) {
        const refreshToken = window.localStorage.getItem('refreshToken');
        const originalConfig = error.config;

        if (!isRefreshing) {
          isRefreshing = true;
          api
            .post('/auth/refresh', {
              refreshToken,
            })
            .then((response) => {
              const { token: newToken, refreshToken: newRefreshToken } =
                response.data;

              window.localStorage.setItem('accessToken', newToken);
              window.localStorage.setItem('refreshToken', newRefreshToken);

              api.defaults.headers['Token'] = `Bearer ${newToken}`;

              failedRequestsQueue.forEach((request) =>
                request.onSuccess(newToken)
              );
              failedRequestsQueue = [];
            })
            .catch((err) => {
              failedRequestsQueue.forEach((request) => request.onFailure(err));
              failedRequestsQueue = [];

              // logout
              signOut();
            })
            .finally(() => {
              isRefreshing = false;
            });
        }
        signOut();

        return new Promise((resolve, reject) => {
          failedRequestsQueue.push({
            onSuccess: (token: string) => {
              originalConfig.headers['Token'] = `Bearer ${token}`;
              resolve(api(originalConfig));
            },
            onFailure: (err: AxiosError) => {
              reject(err);
            },
          });
        });
      }
      return Promise.reject(error);
    }
  );

  return api;
}
