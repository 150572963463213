import { Box, Button, FormHelperText, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import type { FC } from 'react';
import * as Yup from 'yup';
import useAuth from '../../../hooks/useAuth';
import useMounted from '../../../hooks/useMounted';

const YupSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required'),
  password: Yup.string().max(255).required('Password is required'),
});

const InitialForm = {
  email: '',
  password: '',
  submit: null,
};

const LoginJWT: FC = () => {
  const mounted = useMounted();
  const { signIn } = useAuth();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: InitialForm,
    validationSchema: YupSchema,
    onSubmit: () => handleSubmit(),
  });

  const handleSubmit = async () => {
    try {
      await signIn({
        userEmail: formik.values.email,
        userPassword: formik.values.password,
      });

      if (mounted.current) {
        formik.setStatus({ success: true });
        formik.setSubmitting(false);
      }
    } catch (err) {
      if (mounted.current) {
        formik.setStatus({ success: false });
        formik.setErrors({
          submit: err?.response?.data?.message,
        });
        formik.setSubmitting(false);
      }
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <TextField
        autoFocus
        error={Boolean(formik.touched.email && formik.errors.email)}
        fullWidth
        helperText={formik.touched.email && formik.errors.email}
        label="Email Address"
        margin="normal"
        name="email"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="email"
        value={formik.values.email}
        variant="outlined"
      />
      <TextField
        error={Boolean(formik.touched.password && formik.errors.password)}
        fullWidth
        helperText={formik.touched.password && formik.errors.password}
        label="Password"
        margin="normal"
        name="password"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="password"
        value={formik.values.password}
        variant="outlined"
      />
      {formik.errors.submit && (
        <Box sx={{ mt: 3 }}>
          <FormHelperText error>{formik.errors.submit}</FormHelperText>
        </Box>
      )}
      <Box sx={{ mt: 2 }}>
        <Button
          color="primary"
          disabled={formik.isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Log In
        </Button>
      </Box>
    </form>
  );
};

export default LoginJWT;
